import React from 'react';
import styled, { css } from 'styled-components';
import { background, height, space, width, position } from 'styled-system';
import _ from 'lodash';

import useColors from 'hooks/use-colors';

import SlantSVG from 'assets/marketing/slant';
import Imgix from 'shared/components/imgix';

const HeroSlant = (props) => {
  const { color, cta, imgSrc, headerCopy, marketingCopy } = props;
  const colors = useColors();
  const colorMap = {
    green: colors.primaryGreen,
    purple: colors.primaryPurple,
    opal: colors.opal04,
  };

  return (
    <SlantedHeader {...props}>
      <ContentSection>
        <Header>{headerCopy}</Header>
        {!!marketingCopy && _.map(marketingCopy, (copy, i) => <Paragraph key={i}>{copy}</Paragraph>)}
        {!!cta && <CtaContainer>{cta}</CtaContainer>}
      </ContentSection>
      <SlantContainer>
        <div>
          <AccentBlock accentColor={color} height='27%' width='22%' />
          <AccentBlock accentColor={color} height='33%' width='22%' bottomLeft />
          <AccentBlock accentColor={color} height='22%' width='13%' bottomRight />
          <AccentBlock accentColor={color} height='33%' width='20%' bottomRightStack />
        </div>
        <HeroImage>
          <ImageContainer>
            <Imgix src={imgSrc} htmlAttributes={{ alt: 'Dutchie office' }} />
          </ImageContainer>
        </HeroImage>
        <SvgContainer>
          <SlantSVG fill={colorMap[color]} />
        </SvgContainer>
      </SlantContainer>
    </SlantedHeader>
  );
};

export default HeroSlant;

const AccentBlock = styled.span`
  --opal-gradient: linear-gradient(180deg, hsla(209, 21%, 63%, 1) 0%, hsla(209, 21%, 63%, 0) 100%);

  position: absolute;
  clip-path: polygon(31% 0, 100% 0, 68% 100%, 0% 100%);
  mix-blend-mode: normal;
  opacity: 0.51;
  ${background};
  ${height};
  ${width};
  ${space};
  ${position}
  ${({ accentColor, bottomLeft, bottomRight, bottomRightStack }) => {
    if (bottomLeft) {
      return css`
        bottom: 2.5rem;
        transform: rotate(-180deg);
        opacity: 0.41;
        z-index: 10;
        left: -1%;
        clip-path: polygon(35% 0, 100% 0, 60% 100%, 0% 100%);
        background: ${() =>
          accentColor === 'opal'
            ? `var(--opal-gradient);`
            : `linear-gradient(180.28deg, #66DAB5 0.19%, rgba(102, 218, 181, 0) 79.32%);`};
      `;
    }
    if (bottomRight) {
      return css`
        opacity: 0.3;
        background: ${() =>
          accentColor === 'opal'
            ? `var(--opal-gradient);`
            : `linear-gradient(17.5deg, #66DAB5 3.65%, rgba(102, 218, 181, 0) 93.23%);`};
        bottom: 5%;
        right: 28%;
        transform: ${() => (accentColor === 'opal' ? `rotate(-180deg);` : `none`)};
        z-index: 10;
      `;
    }
    if (bottomRightStack) {
      return css`
        opacity: 0.31;
        background: ${() =>
          accentColor === 'opal'
            ? `var(--opal-gradient);`
            : `linear-gradient(17.52deg, #66DAB5 3.65%, rgba(102, 218, 181, 0) 93.23%);`};
        bottom: 0;
        right: 32%;
        transform: ${() => (accentColor === 'opal' ? `rotate(-180deg);` : `none`)};
        z-index: 10;
      `;
    }
    return css`
      background: ${() =>
        accentColor === 'opal'
          ? `var(--opal-gradient);`
          : `linear-gradient(180.27deg, #66DAB5 0.24%, rgba(102, 218, 181, 0) 79.38%);`};
      left: 33.5%;
    `;
  }}
`;

const CtaContainer = styled.div`
  margin-top: 30px;
`;

const ContentSection = styled.section`
  background-color: white;
  display: flex;
  flex: 1 1 50%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 10;
  width: 100%;
  padding: 150px 0 75px 150px;
  @media (min-width: 1280px) and (max-width: 1350px) {
    padding: 150px 0 75px 75px;
  }
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    padding: 0;
    text-align: center;
    width: auto;
    display: flex;
    align-items: center;
    margin: 50px auto;
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    max-width: 325px;
  }
`;

const Header = styled.h1`
  line-height: 37px;
  font-size: 34px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    max-width: 522px;
    line-height: 53px;
    font-size: 50px;
  }
`;

const HeroImage = styled.div`
  height: 100%;
  width: 100%;
  max-height: 813px;
  max-width: 863px;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 769px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  filter: drop-shadow(0px 20px 40px rgba(12, 55, 42, 0.3));
  margin-top: 75px;
  img {
    width: calc(100% + 40px);
    object-fit: contain;
    clip-path: polygon(17% 0, 100% 0, 100% 100%, 0% 100%);
    position: relative;
    z-index: 10;
    margin-left: -40px;
    ${({ theme }) => theme.breakpoints.down(`sm`)} {
      margin-left: -20px;
      margin-top: 10px;
      width: calc(100% + 20px);
    }
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-top: 0;
  }
`;

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin-top: 30px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    font-size: 17px;
    line-height: 30px;
    max-width: 467px;
  }
`;

const SlantedHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    flex-direction: column-reverse;
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-top: 0;
  }
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    max-width: 1440px;
    margin: 0 auto;
  }
`;

const SlantContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 10;
  height: fit-content;
  width: auto;
  margin-left: auto;
  flex: 1 1 50%;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    align-self: flex-end;
    width: 90%;
    margin-top: 80px;
    flex: 1 1 100%;
  }
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    position: absolute;
    right: 0;
  }
  ${({ theme }) => theme.breakpoints.up(`xl`)} {
    position: relative;
  }
  @media (max-width: 1440px) {
    position: relative;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  svg {
    height: 100%;
    width: 100%;
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    display: block;
  }
`;
