import React from 'react';

function SvgSlant(props) {
  return (
    <svg width='819' height='707' viewBox='0 0 819 707' fill='none' {...props}>
      <path d='M214.682 -1H849V707H0L214.682 -1Z' />
    </svg>
  );
}

export default SvgSlant;
