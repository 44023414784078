import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';
import routeTo from 'utils/route-to';

import Button from 'components/core/button';
import MarketingHero from 'components/hero-slant';
import MarketingNavigation from 'marketing/components/marketing-navigation';
import MobileMarketingNavigation from 'marketing/components/marketing-navigation/mobile-marketing-navigation';
import { DesktopOnly, MobileOnly } from 'shared/components';
import { AccountDropdown } from '../../../home/components/account-dropdown';
import { ScheduleDemoButton } from './components';

const AboutHero = () => {
  const { t, Trans } = useTranslation();
  return (
    <MarketingHero
      color='green'
      headerCopy={t('marketing.easyAccess', 'Creating easy access to cannabis for everyone.')}
      marketingCopy={[
        <Trans i18nKey='marketing.powerOfCannabis'>
          We believe in the power of cannabis and we’ve seen the benefits that countless people, of all ages, have
          experienced. At dutchie, we’re driven by our mission to use technology to provide easy access to cannabis for
          everyone.
        </Trans>,
        <Trans i18nKey='marketing.partnerWithTop'>
          We partner with the top dispensaries around the world to provide software tools and a marketplace that enables
          cannabis e-commerce for pickup or delivery.
        </Trans>,
      ]}
      imgSrc='https://images.dutchie.com/marketing-v3/dutchie-hq.png?auto=format&q=75'
    />
  );
};

const CareerHero = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <MarketingHero
      color='opal'
      headerCopy={t('marketing.innovationDedication', 'Where innovation meets dedication.')}
      marketingCopy={[
        t(
          'marketing.buildingTheFuture',
          // eslint-disable-next-line max-len
          'We’re not just building the future of shopping for cannabis, we’re building a culture of innovation, customer care, and challenge to the status quo.'
        ),
        t(
          'marketing.dedicatedDrivenTeam',
          // eslint-disable-next-line max-len
          'We’re a team of dedicated and driven thinkers that are helping to shape this rapidly emerging industry. If that piques your interest, we want to hear from you.'
        ),
      ]}
      cta={
        <DesktopOnly>
          <StyledButton onClick={onClick} variant='outlineBlack' size='large'>
            {t('marketing.exploreOpportunities', 'Explore opportunities')}
          </StyledButton>
        </DesktopOnly>
      }
      imgSrc='https://images.dutchie.com/marketing-v3/dutchie-mural-hq.png?auto=format&q=75'
    />
  );
};

const MarketingHeader = (props) => {
  const { navigationVariant = `default`, variant = `default` } = props;
  const handleClick = () => {
    routeTo({ url: `/careers#current-opportunities` });
  };
  const colorMap = {
    default: `green`,
    about: `green`,
    careers: `opal`,
    demo: `dark`,
  };
  const colorVariant = colorMap[variant];
  const isOnLight = _.includes(['legal'], variant);

  const ActionButton =
    variant === 'demo' ? (
      <ScheduleDemoButton colorVariant={colorVariant} />
    ) : (
      <>
        <DesktopOnly mediaQuery='lg'>
          <AccountDropdown isOnLight={isOnLight} />
        </DesktopOnly>
        <MobileOnly mediaQuery='md'>
          <AccountDropdown isOnLight={!isOnLight} />
        </MobileOnly>
      </>
    );
  return (
    <Header>
      <NavWrapper>
        <StyledDesktopOnly width={variant === 'demo' ? '100%' : '70%'}>
          <MarketingNavigation variant={navigationVariant} />
        </StyledDesktopOnly>
        <StyledMobileOnly display='flex'>
          <MobileMarketingNavigation variant={navigationVariant} />
        </StyledMobileOnly>
        <StyledDesktopOnly>{ActionButton}</StyledDesktopOnly>
      </NavWrapper>
      <Container>
        {variant === 'careers' && <CareerHero onClick={handleClick} />}
        {variant === 'about' && <AboutHero />}
      </Container>
    </Header>
  );
};

export default MarketingHeader;

const Container = styled.div`
  width: 100%;
  height: fit-content;
`;

const StyledDesktopOnly = styled(DesktopOnly)`
  ${({ width }) => width && `width: ${width}`};
  display: flex;
  flex: auto;
  ul {
    padding-left: 10px;
  }
`;

const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    margin-bottom: 30px;
  }
`;

const NavWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 25px;
  margin-bottom: 80px;
  z-index: 101;
  max-width: 1340px;
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    padding-top: 0;
  }
`;

const StyledMobileOnly = styled(MobileOnly)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  text-transform: none;
`;
